export const initialStateMultiUsers = [
  {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    roles: [],
    billto: "",
  },
  {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    roles: [],
    billto: "",
  },
  {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    roles: [],
    billto: "",
  },
  {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    roles: [],
    billto: "",
  },
  {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    roles: [],
    billto: "",
  },
];
export const initialState = {
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  roles: [],
  billto: "",
};
export const billto = "";

export const roleOptions = [
  {value: "fin", label: "finance"},
  {value: "buyer", label: "buyer"},
  {value: "sales", label: "sales"},
  {value: "pricing", label: "pricing analyst"},
];

export const initialLocationBoolState = {
  locationError: false,
};
export const initialBoolState = [
  {
    firstError: false, // first name
    lastError: false, // last name
    emailError: false, // email
    roleError: false, // roles
  },
];

export const initialMultiBoolState = [
  {
    firstError: false, // first name
    lastError: false, // last name
    emailError: false, // email
    roleError: false, // roles
  },
  {
    firstError: false,
    lastError: false,
    emailError: false,
    roleError: false,
  },
  {
    firstError: false,
    lastError: false,
    emailError: false,
    roleError: false,
  },
  {
    firstError: false,
    lastError: false,
    emailError: false,
    roleError: false,
  },
  {
    firstError: false,
    lastError: false,
    emailError: false,
    roleError: false,
  },
];
