/*jshint node: true */
/*jshint esversion: 10 */
/*jshint multistr: true */

import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { getBillToUsers } from "../../util/API/getBillToUsersAPI";
import { deleteUser } from "../../util/API/userAPI";
import { useMsal, useAccount } from "@azure/msal-react";
import { requestAPI } from "../../authConfig";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Search from "./Search";
import UserContext from "../Context/UserContext";
import ActionContext from "../Context/ActionContext";
import UserListContext from "../Context/UserListContext";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
//import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const UserList = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [loading, setLoading] = useState(true); // eslint-disable-line no-unused-vars
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");
  const [noResults, setNoResults] = useState(false);
  const { ID, setID } = useContext(UserContext); // eslint-disable-line no-unused-vars
  const { action, setAction } = useContext(ActionContext); // eslint-disable-line no-unused-vars
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuID, setMenuID] = useState("");
  const { state, dispatch } = useContext(UserListContext);
  const [open, setOpen] = React.useState(false);
  const [deleteRow, setDeleteRow] = useState("");
  const [deleteSpinner, setDeleteSpinner] = useState(false);

  const useStyles = makeStyles({
    root: {
      marginTop: "4rem",
      position: "fixed",
      top: 0,
      width: 400,
      color: "#3C4257 !important",
    },
    container: {
      backgroundColor: "#fff",
      marginTop: "7.5rem",
      position: "fixed",
      top: 0,
      bottom: 5,
      width: 400,
      border: "1px solid rgba(224, 224, 224, 1)",
      overflowY: "scroll",
    },
    tableBodyLoader: {
      display: "flex",
    },
    loader: {
      height: "auto",
      display: "flex",
      flexGrow: 1,
    },
    tableRow: {
      "&:hover": {
        backgroundColor: "#f6f9fb !important",
      },
    },
    rootButton: {
      textTransform: "capitalize",
      padding: 10,
      minWidth: "125px",
    },
    cancelButtonStyle: {
      backgroundColor: "#FFFFFF",
      color: "#3C4257",
    },
  });

  const handleClickOpenDialogCofirm = (ID) => {
    setID(ID);
    setAnchorEl(null);
    setOpen(true);
    setAction("ADD_USER");
  };

  const handleCloseDialogConfirm = () => {
    setOpen(false);
    setDeleteSpinner(false);
    setID("");
    setAction("ADD_USER");
  };

  const requestSearch = (searchedVal) => {
    let users = state;
    const filteredRows = users.filter((row) => {
      return row.displayname.toLowerCase().includes(searchedVal.toLowerCase());
    });
    if (filteredRows.length === 0) {
      const empty = "No users found";
      setRows(empty);
      setNoResults(true);
      return;
    }
    setRows(filteredRows);
    setNoResults(false);
  };

  const cancelSearch = () => {
    setSearched("");
    setRows(state);
    setNoResults(false);
  };

  const remove = () => {
    setDeleteSpinner(true);
    const user = menuID;
    if (account && inProgress === "none") {
      instance
        .acquireTokenSilent({
          ...requestAPI,
          account: account,
        })
        .then((response) => {
          const result = deleteUser(user, response.accessToken);
          Promise.all([result])
            .then(() => {
              const filteredRows = rows.filter((row) => row.id !== user);
              dispatch({ type: "REMOVE_USER_LIST", data: filteredRows });
            })
            .catch((error) => {
              console.log(error);
            });
          setMenuID("");
          handleCloseDialogConfirm();
          setDeleteSpinner(false);
          setID("");
          setAction("ADD_USER");
        });
    }
  };

  const profileSelect = (ID) => {
    console.log("profileSelect");
    setID(ID);
    setAction("EDIT_PROFILE");
  };

  const edit = () => {
    console.log("edit");
    setID(menuID);
    setAction("EDIT_PROFILE");
    setAnchorEl(null);
  };

  const handleClick = (e, ID, row) => {
    setMenuID(ID);
    setDeleteRow(row);
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuID("");
    setAnchorEl(null);
  };

  useEffect(() => {
    console.log("inProgress", inProgress);
    if (account && inProgress === "none") {
      instance
        .acquireTokenSilent({
          ...requestAPI,
          account: account,
        })
        .then((response) => {
          console.log("getUserList");
          getBillToUsers(response.accessToken).then((response) =>
            dispatch({ type: "USER_LIST", data: response.data })
          );
        });
    }
  }, []); // eslint-disable-line  react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(false);
    setRows(state);
  }, [state]);

  const classes = useStyles();
  let { displayname } = deleteRow;
  return (
    <>
      <div className={classes.root}>
        <Search
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
      </div>
      <TableContainer className={classes.container}>
        <Table size="medium" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontSize: "16px" }}>User</TableCell>
              <TableCell style={{ fontSize: "16px" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!noResults ? (
              rows.map((row, index) => (
                <TableRow className={classes.tableRow} key={index}>
                  <TableCell
                    style={{ cursor: "pointer", padding: "0.5em" }}
                    component="th"
                    scope="row"
                    align="left"
                    onClick={() => profileSelect(row.id)}
                  >
                    <div>
                      {row.displayname
                        ? row.displayname
                        : `${row.lastName}, ${row.firstName}`}
                    </div>
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {" "}
                      {row.email}
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      aria-label="Options elipsis"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleClick(e, row.id, row)}
                    >
                      <MoreHorizIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow className={classes.tableRow}>
                <TableCell component="th" scope="row" align="right">
                  <div>{rows}</div>
                </TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleMenuClose()}
      >
        <MenuItem
          aria-label="edit user"
          style={{ fontSize: "14px" }}
          onClick={() => edit()}
        >
          Edit{" "}
        </MenuItem>
        <MenuItem
          aria-label="delete user"
          style={{ fontSize: "14px" }}
          onClick={handleClickOpenDialogCofirm}
        >
          Delete
        </MenuItem>
      </Menu>
      <Dialog
        onClose={handleCloseDialogConfirm}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div style={{ width: "400px" }}>
          <DialogTitle>
            <span
              style={{ color: "#1a1f36", fontSize: "16px", fontWeight: "500" }}
            >
              Delete user
            </span>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={1}>
              <Grid item xs={4} md={4} lg={4}>
                <span
                  style={{
                    color: "#1a1f36",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  User selected
                </span>
              </Grid>
              <Grid item xs={8} md={8} lg={8}>
                <span
                  style={{
                    color: "#556cd6",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {displayname}
                </span>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ padding: "1em" }}>
            <Button
              variant="contained"
              className={classNames(
                classes.rootButton,
                classes.cancelButtonStyle
              )}
              autoFocus
              onClick={handleCloseDialogConfirm}
            >
              Don't Delete
            </Button>
            <Button
              variant="contained"
              className={classes.rootButton}
              autoFocus
              onClick={remove}
              color="primary"
            >
              {deleteSpinner ? (
                <CircularProgress style={{ color: "#fff" }} size="1rem" />
              ) : null}
              Delete User
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default UserList;
