/*jshint node: true */
/*jshint esversion: 10 */
/*jshint multistr: true */

import React, { useEffect, useState, useContext } from "react";
import { getDistributors } from "../../util/API/getDistributorsAPI";
import { useMsal, useAccount } from "@azure/msal-react";
import { requestAPI } from "../../authConfig";
import { makeStyles } from "@material-ui/core/styles";
import { createUser } from "../../util/API/userAPI";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "react-select";
import { CardActions, Typography } from "@material-ui/core";
import ActionContext from "../Context/ActionContext";
import UserListContext from "../Context/UserListContext";
import CloseIcon from "@material-ui/icons/Close";
import AddBoxIcon from "@material-ui/icons/AddBox";
import {
  initialStateMultiUsers,
  initialState,
  billto,
  roleOptions,
  initialMultiBoolState,
  initialBoolState,
  initialLocationBoolState,
} from "./DefaultData";
import { components } from "react-select";
import classNames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { SnackbarContent } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import RoleTooltip from "../RoleTooltip";

const Option = (props) => {
  return (
    <div>
      {" "}
      <components.Option {...props}>
        {" "}
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.value}</label>{" "}
      </components.Option>{" "}
    </div>
  );
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "2px solid #f6f9fb",
    backgroundColor: state.isSelected ? "#556cd6" : "#fff",
    ":active": {
      backgroundColor: "#556cd6",
    },
    ":hover": {
      backgroundColor: state.isSelected ? "#556cd6" : "#556cd6",
      color: state.isSelected ? "#ffd" : "#fff",
    },
  }),
};

const AddMutipleUsers = () => {
  const { instance, accounts, inProgress } = useMsal();
  const [loading, setLoading] = useState(true); // eslint-disable-line no-unused-vars
  const account = useAccount(accounts[0] || {});
  const [errorMessage, setErrorMessage] = useState([]);
  const [error, setError] = useState([...initialMultiBoolState]);
  const [locationError, setLocationError] = useState(initialLocationBoolState);
  const [locationFormData, setLocationFormData] = useState({ billto });
  const [locationOptions, setLocationOptions] = useState();
  const [roles, setRoles] = useState(roleOptions); // eslint-disable-line no-unused-vars
  const { action, setAction } = useContext(ActionContext); // eslint-disable-line no-unused-vars
  const { state, dispatch } = useContext(UserListContext); // eslint-disable-line no-unused-vars
  const [saveLoading, setSaveLoading] = useState(false); // eslint-disable-line no-unused-vars
  const [arrayData, setArrayData] = useState([...initialStateMultiUsers]);
  const [addedCounter, setAddedCounter] = useState(0);
  const [addedCounterError, setAddedCounterError] = useState(0);
  const [rejectArray, setRejectArray] = useState([]);
  const [promiseComplete, setPromiseComplete] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const useStyles = makeStyles((theme) => ({
    snackBar: {
      position: "absolute",
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: theme.palette.background.paper,
    },
    backdropUpdating: {
      position: "absolute",
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
      backgroundColor: "rgba(246, 249, 251, 0.3)",
    },
    backdrop: {
      position: "absolute",
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
      backgroundColor: "rgba(246, 249, 251, 1)",
    },
    cardStyle: {
      marginTop: "4rem",
      fontFamily:
        "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif",
      backgroundColor: "#f6f9fb",
      width: "100%",
      position: "relative",
    },
    rootCardContent: {
      backgroundColor: "#f6f9fb",
      padding: "0.25em",
      borderBottom: "1px solid rgba(224, 224, 224, 1);",
    },
    cardStyleHeader: {
      height: 57,
      marginBottom: 1,
      borderBottom: "1px solid rgba(224, 224, 224, 1);",
      fontSize: 14,
      backgroundColor: "#FFFFFF",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: "500",
      paddingLeft: "1em",
      fontFamily:
        "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif",
    },
    cardStyleActions: {
      backgroundColor: "#FFFFFF",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      fontWeight: "500",
      height: 65,
      paddingRight: "4em",
    },
    footer: {
      marginRight: "0.75em",
      padding: "0.75em",
    },

    form: {
      "& > *": {
        margin: theme.spacing(3),
        color: "#3C4257",
        fontWeight: "500",
        textAlign: "left",
        //width: '25ch',
      },
    },
    labelStyle: {
      padding: "0 0 0.1em",
    },
    textFieldStyle: {
      backgroundColor: "#FFFFFF",
      color: "#3C4257",
    },
    rootButton: {
      textTransform: "capitalize",
    },
    resetButtonStyle: {
      backgroundColor: "#FFFFFF",
      color: "#3C4257",
    },
    rootSelect: {
      margin: 0,
      padding: "0.5em",
      paddingLeft: 0,

      textTransform: "capitalize",
    },
    closeButtonStyle: {
      "&:hover": {
        color: "blue",
      },
    },
  }));

  const classes = useStyles();

  const goBack = () => {
    resetForm();
    setAction("ADD_USER");
  };
  const handleDummyChange = (index, e) => {
    let newArrayData = arrayData.slice();
    newArrayData[index][e.target.name] = e.target.value;
    setArrayData(newArrayData);
  };
  const handleBillToChange = (selectedBillToOption) => {
    setLocationFormData({
      ...locationFormData,
      billto: {
        selectedBillToOption,
      },
    });
  };
  const handleEmailChange = (index, e) => {
    let email = e.target.value;
    let newErrorArray = [...error];
    if (email === "") {
      newErrorArray[index]["emailError"] = true;
      setError(newErrorArray);
      return;
    }
    let validiate = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

    if (!validiate.test(email)) {
      newErrorArray[index]["emailError"] = true;
      setError(newErrorArray);
      return;
    }
    newErrorArray[index]["emailError"] = false;
    setError(newErrorArray);

    let newArrayData = arrayData.slice();
    newArrayData[index][e.target.name] = e.target.value;
    setArrayData(newArrayData);
  };

  const handleDummyRolesChange = (index, selectedRolesOption) => {
    let newArrayData = arrayData.slice();
    newArrayData[index]["roles"] = { selectedRolesOption };
    setArrayData(newArrayData);
  };

  const resetForm = () => {
    // Array.from(document.querySelectorAll("input"));
    setArrayData([
      {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        roles: [],
        billto: "",
      },
      {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        roles: [],
        billto: "",
      },
      {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        roles: [],
        billto: "",
      },
      {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        roles: [],
        billto: "",
      },
      {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        roles: [],
        billto: "",
      },
    ]);
    setLocationFormData({ billto });
    setError([
      {
        firstError: false,
        lastError: false,
        emailError: false,
        roleError: false,
      },
      {
        firstError: false,
        lastError: false,
        emailError: false,
        roleError: false,
      },
      {
        firstError: false,
        lastError: false,
        emailError: false,
        roleError: false,
      },
      {
        firstError: false,
        lastError: false,
        emailError: false,
        roleError: false,
      },
      {
        firstError: false,
        lastError: false,
        emailError: false,
        roleError: false,
      },
    ]);
    setLocationError(initialLocationBoolState);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setSaveLoading(true);

    let { billto: selectedBillToOption } = locationFormData;
    let { billto } = locationFormData;
    let newLocationError = [{ ...locationError }];

    if (billto.length === 0) {
      newLocationError["locationError"] = true;
      setLocationError(newLocationError);
      setSaveLoading(false);
      return;
    }
    if (selectedBillToOption.length === 0) {
      newLocationError["locationError"] = true;
      setLocationError(newLocationError);
      setSaveLoading(false);
      return;
    }
    setLocationError(initialLocationBoolState);
    const addMoreArray = JSON.parse(JSON.stringify([...arrayData]));
    for (let i = 0; i < addMoreArray.length; i++) {
      let { firstName, lastName, email, roles } = addMoreArray[i];
      if (
        firstName.length === 0 &&
        lastName.length === 0 &&
        email.length === 0 &&
        roles.length === 0
      ) {
        continue; // skip blank lines
      }
      let {
        roles: { selectedRolesOption },
      } = addMoreArray[i];
      let newErrorArray = [...error];
      if (firstName === "") {
        newErrorArray[i]["firstError"] = true;
        setError(newErrorArray);
        setSaveLoading(false);
        return;
      }
      if (lastName === "") {
        newErrorArray[i]["lastError"] = true;
        setError(newErrorArray);
        setSaveLoading(false);
        return;
      }
      if (email === "") {
        newErrorArray[i]["emailError"] = true;
        setError(newErrorArray);
        setSaveLoading(false);
        return;
      }
      let validiate = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

      if (!validiate.test(email)) {
        newErrorArray[i]["emailError"] = true;
        setError(newErrorArray);
        setSaveLoading(false);
        return;
      }

      if (roles.length === 0) {
        newErrorArray[i]["rolesError"] = true;
        setError(newErrorArray);
        setSaveLoading(false);
        return;
      }
      if (selectedRolesOption.length === 0) {
        newErrorArray[i]["rolesError"] = true;
        setError(newErrorArray);
        setSaveLoading(false);
        return;
      }
    }

    let location = [{ ...locationFormData }];
    location = location.flatMap((a) => a.billto);
    let selectedBillTo = location.flatMap((a) => a.selectedBillToOption);
    let extractBillTo = selectedBillTo
      .flatMap((option) => option.value)
      .join(",");

    let formatArray = [];
    addMoreArray.forEach((array) => {
      console.log(array);
      let tmp = [array];
      if (tmp[0].email.length === 0) {
        return;
      }
      let roles = tmp.flatMap((a) => a.roles);
      let selectedRoles = roles.flatMap((a) => a.selectedRolesOption);
      let extractRolesMore = selectedRoles.flatMap((option) => option.value);
      extractRolesMore.forEach((option) => {
        if (option.value === "finance") {
          option.value = "fin";
        }
      });
      tmp[0]["billto"] = extractBillTo;
      tmp[0]["roles"] = extractRolesMore;
      formatArray.push(tmp[0]);
    });
    create(formatArray); // calls create function which calls the API for each user.
  };

  const create = (payLoad) => {
    let data = payLoad;
    let promises = [];
    if (account && inProgress === "none") {
      instance
        .acquireTokenSilent({
          ...requestAPI,
          account: account,
        })
        .then((response) => {
          let index = 0;
          data.forEach((userData) => {
            promises.push(createUSER(userData, response.accessToken, index));
            index++;
          });
          Promise.allSettled(promises).then(() => {
            setPromiseComplete(true);
          });
        });
    }
  };

  const createUSER = async (userData, accessToken, index) => {
    await new Promise(function (resolve, reject) {
      createUser(userData, accessToken)
        .then((res) => {
          dispatch({ type: "ADD_USER_LIST", data: res.data });
          setAddedCounter((prevCount) => prevCount + 1);
          resolve();
        })
        .catch((e) => {
          console.error(`${e.name}: ${e.message}`);
          setRejectArray((prevState) => [...prevState, index]);
          setAddedCounterError((prevCount) => prevCount + 1);
          let newErrorArray = [...error];
          newErrorArray[index]["emailError"] = true;
          setError(newErrorArray);
          reject();
        });
    });
  };

  const handleRemoveMore = (index) => {
    let tmp = [...arrayData];
    tmp.splice(index, 1);
    setArrayData((prevState) => tmp);
    let tmpError = [...error];
    tmpError.splice(index, 1);
    setError((prevState) => tmpError);
  };

  const handleAddMore = () => {
    let tmp = [];
    setArrayData((prevState) => tmp.concat(prevState, [{ ...initialState }]));
    let tmpError = [];
    setError((prevState) => tmpError.concat(prevState, initialBoolState));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    console.log("Error ARRAY", error);
  }, [error]);

  useEffect(() => {
    console.log("Form Data Update:", locationFormData);
  }, [locationFormData]);

  useEffect(() => {
    if (rejectArray.length > 0) {
      console.log("rejectpath taken");
      let arrayFormData = [...arrayData];
      let newArrayFormData = [];
      rejectArray.forEach((reject) => {
        newArrayFormData.push(arrayFormData[reject]);
      });
      setArrayData(newArrayFormData);
      setMessage(`There was an error adding ${addedCounterError} users`);
      setSaveLoading(false);
      setOpen(true);
    } else if (addedCounter > 0) {
      resetForm();
      setSaveLoading(false);
      setMessage(`Users added: ${addedCounter}`);
      setOpen(true);
    }
  }, [promiseComplete]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(account, inProgress, instance);
    setLoading(true);
    if (account && inProgress === "none") {
      instance
        .acquireTokenSilent({
          ...requestAPI,
          account: account,
        })
        .then((response) => {
          //console.log("beforeGetDistrib locationOptions", locationOptions);
          //console.log("arrayData", arrayData);
          getDistributors(response.accessToken).then((response) => {
            let tmp2 = Object.values(response.data);
            let locOptions = tmp2.flatMap((t) => [
              {
                value: t.billto,
                label: `${t.name} (${t.billto})`,
              },
            ]);
            setLocationOptions(locOptions);
            setLoading(false);
          });
        });
    }
    setErrorMessage([
      ["* Required"],
      ["*Required"],
      ["* Invalid Email Address"],
      ["* Required"],
      ["* Required"],
    ]);
  }, [account, inProgress, instance]);

  let {
    billto: { selectedBillToOption },
  } = locationFormData;

  return (
    <Card className={classes.cardStyle}>
      {saveLoading ? (
        <Backdrop className={classes.backdropUpdating} open={true}>
          <CircularProgress color="primary" size="5rem" />
        </Backdrop>
      ) : null}
      <div className={classes.cardStyleHeader}>
        {loading ? (
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="primary" size="5rem" />
          </Backdrop>
        ) : null}
        <Button onClick={() => goBack()} className={classes.rootButton}>
          <ArrowBackIosIcon style={{ fontSize: "24px" }} />
          <Typography
            style={{ margin: "0", fontSize: "16px", fontWeight: "500" }}
          >
            Multi Add Users
          </Typography>
        </Button>
      </div>
      <CardContent className={classes.rootCardContent}>
        <form className={classes.form} noValidate autoComplete="off">
          <Grid container direction="column">
            <Grid item xs={9} md={9} lg={9} zeroMinWidth direction="column">
              <span>
                <label className={classes.labelStyle} htmlFor="billto">
                  Location
                </label>
                <Tooltip
                  title={
                    <React.Fragment>
                      <Typography fontSize="12px" color="inherit">
                        The location(s) selected here will apply to all users
                        added in this session.
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <IconButton
                    size="small"
                    aria-label="close tool tip"
                    color="inherit"
                    onClick={handleClose}
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </span>
              <FormHelperText margin="dense" error>
                {locationError.locationError && errorMessage[4]}
              </FormHelperText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Select
                  className={classes.rootSelect}
                  options={locationOptions}
                  maxMenuHeight={250}
                  menuPlacement="auto"
                  value={selectedBillToOption ? selectedBillToOption : ""}
                  onChange={handleBillToChange}
                  isMulti={true}
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            style={{ marginTop: "0", marginBottom: "0", PaddingBottom: "0" }}
          >
            <Grid item xs={2} md={2} lg={2}>
              <div>
                <label className={classes.labelStyle} htmlFor="firstName">
                  First Name
                </label>
              </div>
            </Grid>
            <Grid item xs={2} md={2} lg={2}>
              <label className={classes.labelStyle} htmlFor="lastName">
                Last Name
              </label>
            </Grid>
            <Grid item xs={2} md={2} lg={2}>
              <label className={classes.labelStyle} htmlFor="email">
                Email
              </label>
            </Grid>
            <Grid item xs={2} md={2} lg={2}>
              <label className={classes.labelStyle} htmlFor="phone">
                Phone Number
              </label>
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <span>
                <label className={classes.labelStyle} htmlFor="roles">
                  Role
                </label>
                <RoleTooltip />
              </span>
            </Grid>
          </Grid>
          {arrayData
            ? Object.values(arrayData).map((data, index) => (
                <Grid
                  container
                  spacing={2}
                  key={index}
                  style={{ marginTop: "0", marginBottom: "0" }}
                >
                  <Grid item xs={2} md={2} lg={2}>
                    <FormHelperText margin="dense" error>
                      {error[index].firstError ? errorMessage[0] : ""}
                    </FormHelperText>
                    <TextField
                      className={classes.textFieldStyle}
                      variant="outlined"
                      name="firstName"
                      id={"firstName" + index}
                      margin="dense"
                      value={data.firstName}
                      error={error[index].firstError}
                      onChange={(e) => handleDummyChange(index, e)}
                    />
                  </Grid>
                  <Grid item xs={2} md={2} lg={2}>
                    <FormHelperText margin="dense" error>
                      {error[index].lastError ? errorMessage[1] : ""}
                    </FormHelperText>
                    <TextField
                      className={classes.textFieldStyle}
                      id={"lastName" + index}
                      name="lastName"
                      variant="outlined"
                      margin="dense"
                      value={data.lastName}
                      error={error[index].lastError}
                      onChange={(e) => handleDummyChange(index, e)}
                    />
                  </Grid>
                  <Grid item xs={2} md={2} lg={2}>
                    <FormHelperText margin="dense" error>
                      {error[index].emailError && errorMessage[2]}
                    </FormHelperText>
                    <TextField
                      className={classes.textFieldStyle}
                      id={"email" + index}
                      name="email"
                      variant="outlined"
                      value={data.email}
                      error={error[index].emailError}
                      onChange={(e) => handleDummyChange(index, e)}
                      onBlur={(e) => handleEmailChange(index, e)}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={2} md={2} lg={2}>
                    <FormHelperText margin="dense"></FormHelperText>
                    <TextField
                      className={classes.textFieldStyle}
                      id={"phone" + index}
                      name="phone"
                      variant="outlined"
                      value={data.phone}
                      onChange={(e) => handleDummyChange(index, e)}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={3} md={3} lg={3}>
                    <FormHelperText margin="dense" error>
                      {error[index].roleError && errorMessage[3]}
                    </FormHelperText>
                    <Select
                      components={{ Option }}
                      className={classes.rootSelect}
                      styles={customStyles}
                      options={roles}
                      maxMenuHeight={250}
                      menuPlacement="auto"
                      value={
                        data.roles["selectedRolesOption"]
                          ? data.roles["selectedRolesOption"]
                          : ""
                      }
                      onChange={(e) => handleDummyRolesChange(index, e)}
                      isMulti={true}
                      hideSelectedOptions={false}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={0}
                    md={0}
                    lg={0}
                    style={{ alignSelf: "center", padding: "0" }}
                    spacing={0}
                  >
                    <Button
                      style={{
                        marginTop: "0",
                        padding: "0",
                        marginRight: "0",
                        marginLeft: "0",
                      }}
                      onClick={() => handleRemoveMore(index)}
                    >
                      <CloseIcon
                        style={{ position: "absolute", left: 0 }}
                        className={classes.closeButtonStyle}
                      ></CloseIcon>
                    </Button>
                  </Grid>
                </Grid>
              ))
            : null}
          <Grid spacing={1}>
            <Grid item xs={2} md={2} lg={2}>
              <Button
                className={classes.rootButton}
                style={{ color: "#3C4257" }}
                onClick={() => handleAddMore()}
              >
                <AddBoxIcon color="primary"></AddBoxIcon>
                Add More
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
      <CardActions className={classes.cardStyleActions}>
        <span style={{ padding: "0.25em" }}>
          <Button
            variant="contained"
            color="secondary"
            className={classNames(classes.rootButton, classes.resetButtonStyle)}
            onClick={() => resetForm()}
          >
            Clear Form
          </Button>
        </span>
        <Button
          variant="contained"
          className={classes.rootButton}
          onClick={(e) => handleSave(e)}
          color="primary"
        >
          Add Users
        </Button>
      </CardActions>
      <Snackbar
        className={classes.snackBar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{ backgroundColor: "#fff", color: "#3C4257" }}
          message={message}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Snackbar>
    </Card>
  );
};
export default AddMutipleUsers;
